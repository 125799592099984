.ShopProducts {
  width: 100%;
  &__heading {
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 19px;
  }

  &__products {
    & > * {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__spinner {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
