@import "./src/styles/lib";

.CampaignGrid {
  width: 100%;
  position: relative;
  display: grid;
  grid-auto-rows: 165px;
  grid-template-columns: repeat(4, 4fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  margin-bottom: 20px;

  @include tablet {
    grid-gap: 30px;
    grid-template-columns: repeat(2, 2fr);
    margin-bottom: 35px;
  }

  @include mobile {
    grid-gap: 15px;
    grid-template-columns: repeat(2, 2fr);
    margin-bottom: 35px;
  }

  &__item {
    display: block;
    width: 100%;
    position: relative;
    border-bottom: 10px solid $dark-gray;
    cursor: pointer;
    &:hover {
      .CampaignGrid__overlay {
        background: rgba(0, 0, 0, 0.5);
      }
    }

    @include mobile {
      order: 1;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
    }

    &:first-child {
      p {
        font-size: 35px;
        bottom: 15px;
      }
      grid-row: 1 / 3;
      grid-column: 2 / 4;

      @include tablet {
        order: 0;
        grid-column: 1/3;
        bottom: 15px;
      }

      @include mobile {
        order: 0;
        grid-row: 1 / 3;
        grid-column: 1 / 3;
      }
    }
    &:after {
      content: "";
    }
  }
  &__overlay {
    transition: $transition1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(0, 0, 0, 0.4);
  }

  &__image {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    object-fit: cover;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 26px;
    font-weight: 700;
    line-height: 18px;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
    z-index: 4;
    @include mobile {
      // font-size: 12px;
      font-size: 20px;
      bottom: 5px;
    }
  }

  &__loading_placeholders {
    &--item {
      // min-height: 210px;
      height: 100%;

      @include mobile {
        min-height: unset;
        // width: 100%;
      }
      @media (max-width: 768px) {
        // width: 100%;
      }
      @media (max-width: 1024px) {
        // width: 100%;
      }
    }

    &--big {
      width: 100%;
      height: 100%;
      grid-row: 1/3;
      grid-column: 2/4;

      @include mobile {
        grid-row: 1;
        grid-column: 1/3;
        height: 273px;
      }
      @media (max-width: 768px) {
        grid-row: 1;
        grid-column: 1/3;
        height: 273px;
      }

      clipPath {
        rect {
          height: 500px;

          @include mobile {
            height: 273px;
          }
        }
      }
    }
  }
}
