@import "../../styles/lib";
.Contact {
  &__wrapper {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    @include mobile {
      flex-direction: column;
    }
  }
  &__info {
    width: 100%;
  }
  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include mobile {
      gap: 20px;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    line-height: 21px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 40px;
    p {
      margin: 0;
    }
    @include mobile {
      flex-direction: column;
    }
  }
  &__right {
    min-width: 200px;
  }

  &__container {
    max-width: 540px;
  }
}
