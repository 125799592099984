@import "../../../styles/lib";
.Search {
  position: relative;
  width: 100%;
  &__input {
    width: 100%;
    height: 40px;
    border: 1px solid #d4d4d4;
    background-color: #f5f5f5;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
    padding-left: 10px;
    &:focus {
      outline: 0;
      border: 1px solid $orange;
    }
  }
  &__icon {
    position: absolute;
    width: 20px;
    height: unset;
    top: 10px;
    right: 10px;
    cursor: pointer;
    svg {
      height: 100%;
      fill: $orange;
    }
  }
}
