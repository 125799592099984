.ForgotPassword {
  width: 100%;
  &__wrapper {
    margin: 0 auto;
    margin-top: 70px;
    .Button {
      margin-top: 20px;
    }
  }
  &__info {
    margin-top: 10px;
    font-size: 14px;
  }
}
