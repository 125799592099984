$font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base: "Poppins";

$primary: #58c19e;
$secondary: #ffd72d;

$gray: #a5a5a5;
$gray-text: #9fa4af;
$light-gray: #f3f3f3;
$border-gray: #ededed;

$dark-gray: #353942;
$darkest-gray: #1f1f1f;
$orange: #f07e04;
$green: #38b644;
$error: #d92e2e;

$mq-small: 767px;
$mq-medium: 992px;
$d-small: 1024px;
$d-medium: 1240px;

$error-box-shadow: 0 0 0 0.2rem rgba(217, 46, 46, 0.4);

/*===================================
=            TRANSITIONS            =
===================================*/
/// Google Material Design cubic bezier transition
/// @type Transition
$transition1: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
/// Google Material Design cubic bezier transition slower
/// @type Transition
$transition2: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
/// Google Material Design cubic bezier transition faster
/// @type Transition
$transition3: all 0.2s cubic-bezier(0.6, 0, 0.2, 1);

$transition4: all 10s cubic-bezier(0.6, 0, 0.2, 1);

/*=====  End of TRANSITIONS  ======*/
