@import "../../styles/lib";
.Home {
  width: 100%;
  &__banner {
    margin-top: 1px !important;
  }
  &__row {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    margin: 0 auto;
    margin-bottom: 19px;

    // @include small-desktop{

    // }
    @include tablet {
      flex-direction: column;
    }
    @include mobile {
      flex-direction: column;
    }
  }
  &__slider {
    height: 100% !important;
    // @include desktop {
    //   width: 100% !important;
    // }
    @include small-desktop {
      width: 320px !important;
      height: 100%;
    }
    @include tablet {
      width: 100% !important;
    }
  }
}
