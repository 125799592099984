@import "../../../styles/lib";
.CartSummary {
  background-color: #f4f4f4;
  width: 380px;
  border-radius: 5px;
  padding: 15px 0 20px 0;
  @include mobile {
    width: 100%;
  }

  &__content {
    padding: 0 20px;
  }

  &__title {
    font-weight: 700;
    padding: 0 20px;
    padding-bottom: 10px;
    margin: 0;
    border-bottom: 1px solid #fff;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 15px 0;
    border-bottom: 1px solid #fff;
    font-weight: 600;
    font-size: 14px;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__payment_methods {
    display: flex;
    width: 100%;
    .Icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }
  }

  &__applied_coupons {
    .Coupons__item {
      margin-top: 15px;
    }
  }

  &__row--big {
    // span {
    font-size: 18px !important;
    font-weight: 700 !important;
    // }
  }

  &__row--highlight {
    color: $orange !important;
    font-weight: 700 !important;
  }

  &__row--hide {
    display: none;
  }
}
